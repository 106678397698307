<template>
    <!-- Business-->
  <div class="flex flex-col bg-white h-screen w-full">
    <div class="flex-none h-16">
      <div class="border-2 flex flex-row h-full">
        <div class="flex-none grid place-items-center w-20">
          <app-link
            :to="{ name: 'master-business.list-business' }"
            class="text-black"
            ><app-icon name="XIcon" class="w-5 cursor-pointer"></app-icon
          ></app-link>
        </div>
        <div class="flex-1 border-l-2 flex items-center px-10">
          <app-button-master-business-status />
        </div>
      </div>
    </div>
    <div class="flex-1 h-full md:grid grid-cols-4 block">
      <div class="border-r border-gray-100 px-5 py-10 space-y-2">
        <app-button-menu
          class="w-full space-x-3 flex items-center font-medium"
          v-for="(menu, index) in menu_items"
          v-bind:key="menu.title"
          :fullWidth="true"
          @onMenuClick="onMenuClick(menu)"
          :active="isCurrentPath(menu.path)"
          :disabled="isDisabled(menu)"
          :show="isShow(menu)"
        >
          <span
            class="
              w-8
              h-8
              grid
              border-2
              flex-none
              rounded-full
              place-items-center
            "
            :class="[
              isDone(menu) ? 'border-success bg-success' : 'bg-white',
              isCurrentPath(menu.path)
                ? 'border-primary bg-primary text-white'
                : 'border-gray-400 text-gray-400',
            ]"
          >
            <app-icon
              v-if="isDone(menu)"
              name="CheckIcon"
              class="h-6 w-6 text-white"
            />
            <p v-else>{{ index + 1 }}</p>
          </span>
          <p
            :class="[
              'text-left',
              isDisabled(menu) ? 'text-gray-400' : 'text-black ',
            ]"
          >
            {{ menu.title }}
          </p>
        </app-button-menu>
      </div>
      <div class="col-span-3 md:px-6 py-10 px-10 space-y-2">
        <div class="mb-5 md:pl-10">
          <div
            v-if="
              business.business_status_id == BUSINESS_STATUS.REJECTED ||
              business.business_status_id == BUSINESS_STATUS.AMENDED
            "
          >
            <app-container-comments />
          </div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      menu_items: [
        {
          id: 1,
          title: "Business structure",
          path: "/master-business/business/structure",
        },
        {
          id: 2,
          title: "Business details",
          path: "/master-business/business/detail",
        },
        {
          id: 3,
          title: "Business representative",
          path: "/master-business/business/representative",
        },
        {
          id: 4,
          title: "Business owners",
          path: "/master-business/business/owners",
        },
        {
          id: 5,
          title: "Support details",
          path: "/master-business/business/support-details",
        },
        {
          id: 6,
          title: "Bank details",
          path: "/master-business/business/bank-details",
        },
        {
          id: 7,
          title: "Supporting Documents",
          path: "/master-business/business/support-documents",
        },
        { id: 8, title: "Summary", path: "/master-business/business/summary" },
        {
          id: 9,
          title: "Company Logo",
          path: "/master-business/business/company-logo",
        },
        {
          id: 10,
          title: "Payment Provider",
          path: "/master-business/business/payment-provider"
        },
        {
          id: 10,
          title: "Payout Option",
          path: "/master-business/business/payout-option"
        },
      ],
    };
  },
  computed: {
    business() {
      return this.$store.getters["masterBusinessStore/business"];
    },
    businessLogo() {
      return this.$store.getters["masterBusinessStore/logo"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
    business_id() {
      return this.$route.query.business_id;
    },
    support_documents() {
      return this.$store.getters["masterBusinessStore/support_documents"];
    },
    validate_support_documents() {
      return this.$store.getters[
        "masterBusinessStore/validate_support_documents"
      ];
    },
    testMode() {
      return this.$store.getters["businessStore/test_mode"] ?? false;
    },
  },
  mounted() {
    if (this.business_id) {
      this.$store.dispatch(
        "masterBusinessStore/getMasterBusinessById",
        this.business_id
      );
      this.$store.dispatch(
        "masterBusinessStore/getBusinessSupportDocuments",
        this.business_id
      );
      this.$store.dispatch(
        "masterBusinessStore/validateBusinessSupportDocuments",
        this.business_id
      );
      this.$store.dispatch(
        "masterBusinessStore/typeBusinessSupportDocuments",
        this.business_id
      );
    }
  },
  methods: {
    onMenuClick(menu) {
      this.$router.push({
        path: menu.path,
        query: {
          business_id: this.business_id,
        },
      });
    },

    isCurrentPath(path) {
      return this.$route.path == path;
    },

    isBusinessCreated() {
      return this.business != null ? this.business.id != null : false;
    },

    isBusinessDetailsCreated() {
      return this.business.detail != null
        ? this.business.detail.id != null
        : false;
    },

    isBusinessRepresentativeCreated() {
      return this.business.representative != null
        ? this.business.representative.id != null
        : false;
    },

    isBusinessOwnersCreated() {
      return this.business.owners != null
        ? this.business.owners.length > 0
        : false;
    },

    isBusinessSupportDetailsCreated() {
      return this.business.support_detail != null
        ? this.business.support_detail.id != null
        : false;
    },

    isBusinessBanksCreated() {
      return this.business.bank != null ? this.business.bank.id != null : false;
    },

    isBusinessSubmitted() {
      return this.business.business_status_id >= BUSINESS_STATUS.SUBMITTED;
    },

    isBusinessApproved() {
      return this.business.business_status_id >= BUSINESS_STATUS.APPROVED;
    },

    isBusinessCompanyLogo() {
      return this.businessLogo != null;
    },

    isBusinessSupportDocumentsCreated() {
      return !this.$lodash.isEmpty(this.support_documents);
    },

    isBusinessSupportDocumentsValidate() {
      return this.validate_support_documents;
    },

    isDone(menu) {
      switch (menu.id) {
        case 1:
          return this.isBusinessCreated();
        case 2:
          return this.isBusinessDetailsCreated();
        case 3:
          return this.isBusinessRepresentativeCreated();
        case 4:
          return this.isBusinessOwnersCreated();
        case 5:
          return this.isBusinessSupportDetailsCreated();
        case 6:
          return this.isBusinessBanksCreated();
        case 7:
          return this.isBusinessSupportDocumentsValidate();
        case 8:
          return this.isBusinessSubmitted();
        case 9:
          return this.isBusinessCompanyLogo();
        default:
          return false;
      }
    },

    isDisabled(menu) {
      switch (menu.id) {
        case 1:
          return false;
        case 9:
          return !this.isBusinessApproved();
        default:
          return !this.isBusinessCreated();
      }
    },

    isShow(menu) {
      switch (menu.id) {
        case 1:
          return true;
        case 9:
          return this.isBusinessApproved();
        case 10: 
          return this.isBusinessApproved() || this.testMode;
        case 11:
          return this.isBusinessApproved();
        default:
          return true;
      }
    },
  },
};
</script>
